import * as c from "./constant";
import { Map, fromJS, List } from "immutable";

const initState = Map({
  list: List([]),
  form_data: Map({
    from: "",
    to: "",
  }),
});

export default (state = initState, action) => {
  switch (action.type) {
    case c.GOT_LIST:
      return state.set("list", fromJS(action.data));
    default:
      return state;
  }
};
