import React, { PureComponent } from 'react';               
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Modal from "react-modal";

import moment from 'moment-timezone';
import * as c from '../constant';

class JobInfoModal extends PureComponent {

  componentWillMount() {
    const { dispatch, match } = this.props
    dispatch({
        type: c.CLEAR_FORM_DATA
    })
    if(match.params.id){
        dispatch({
            type:c.GET_JOB_DETAILS,
            id:match.params.id
        })
    }
  }

  handleChangeInput = (e) => {
    e.preventDefault();
    const { dispatch } = this.props
    const { name, value } = e.target;
    dispatch({
        type: c.SET_FORM_DATA,
        data: {
          [name]: value
        }
    });
  };

  handleOnSubmit = (e) => {
      e.preventDefault();
      const { dispatch, form_data } = this.props;
      // dispatch({
      //     type: c.CREATE,
      //     args: {
      //         ...form_data.toJS(),
      //     }
      // })
  }

  handleOnClose = (e) => {
      e.preventDefault();
      const { history } = this.props
      history.push('/jobseeker')
  }

  handleChangeSelect = (key) => (e) => {
		const { dispatch } = this.props
		dispatch({
			type: c.SET_FORM_DATA,
			data: {
				[key]: e ? e.value : ''
			}
		})
    }
    
    register = e => {
        e.preventDefault();
        const { dispatch, history } = this.props;
        dispatch({
            type: 'MODAL',
            data: {
                isOpen: false
            }
        })
        history.push('/register')
    }

    apply = (id ) => e => {
        e.preventDefault();
        const { dispatch } = this.props;
        dispatch({
            type:c.APPLY,
            id
        })
    }

    save = (id ) => e => {
        e.preventDefault();
        const { dispatch } = this.props;
        dispatch({
            type:c.SAVE,
            id
        })
    }

  render() {
    const { job_details, history } = this.props;
    const token = sessionStorage.getItem('token')
    return (
        <div>
        <Modal
            history={history}
            className={`modal-lg modal-dialog`}
            closeTimeoutMS={10}
            contentLabel=""
            ariaHideApp={false}
            isOpen={true}
            // redirect={
            //   selected_data.size > 0
            //     ? `/employee/${selected_data.get("id")}/employment`
            //     : `/employee`
            // }
            title=""
        >
        <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title">{job_details.get('title')}</h4>
                        <button
                            type="button"
                            className="close"
                            onClick={this.handleOnClose}>
                            <span aria-hidden="true">×</span>
                            <span className="sr-only">Close</span>
                        </button>
                    </div>
                    
            <form onSubmit={ this.handleOnSubmit }>

                    <div className="modal-body">
                        <div className="modal-body job-info">
                            <div className="mb-5">
                                <div className="row mt-0">
                                    <div className="col-md-4 font-weight-bold">Employer:</div>
                                    <div className="col-md-8"><b>{job_details.get('client')}</b></div>
                                </div>
                                <div className="row mt-0">
                                    <div className="col-md-4 font-weight-bold">Category:</div>
                                    <div className="col-md-8">{job_details.getIn(['category','name'])}</div>
                                </div>
                                <div className="row mt-0">
                                    <div className="col-md-4 font-weight-bold">Work Location:</div>
                                    <div className="col-md-8">{job_details.getIn(['country','name'])}</div>
                                </div>
                                <div className="row mt-0">
                                    <div className="col-md-4 font-weight-bold">Post Duration:</div>
                                    <div className="col-md-8">{moment(job_details.get('posted')).format('MMM DD, YYYY')} - {moment(job_details.get('expires')).format('MMM DD, YYYY')}</div>
                                </div>
                                <div className="row mt-0">
                                    <div className="col-md-4 font-weight-bold">Gender:</div>
                                    <div className="col-md-8">{job_details.get('gender')}</div>
                                </div>
                            </div>
                            <div className="ml-3" dangerouslySetInnerHTML={{__html: job_details.get('description')}} />
                            <div className="text-center mb-3 mt-5">
                                {
                                    token ?
                                    <div className="btn btn-group btn-block p-0">
                                        {
                                            job_details.get('applied') ?
                                            <button type="submit" className="btn btn-secondary btn-block btn-rounded border-right" >Applied</button>
                                            :
                                            <button type="submit" className="btn btn-primary btn-block btn-rounded border-right" onClick={this.apply(job_details.get('id'))}>Apply</button>
                                        }
                                        {
                                            job_details.get('saved') ?
                                            <button type="submit" className="btn btn-secondary btn-block btn-rounded m-0" >Saved</button>
                                            : 
                                            <button type="submit" className="btn btn-primary btn-block btn-rounded m-0" onClick={this.save(job_details.get('id'))}>Save</button>
                                        }
                                    </div>
                                    
                                    :
                                    <button type="submit" className="btn btn-primary btn-block btn-rounded z-depth-1a" onClick={this.register}>Register to apply</button>
                                }
                                
                                
                            </div>
                        </div>
                    </div>
                    </form>
                </div>
            </Modal>
      </div>
    );
  }
}


const mapPropsToState = (state, props) => {
    const form_data = state.auth.get('form_data');
    const profile = state.auth.get('profile');
    const job_details = state.auth.get('job_details');
    return {
      form_data,
      profile,
      job_details
    };
};

export default withRouter(connect(mapPropsToState)(JobInfoModal));