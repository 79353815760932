export const MODULE = 'SAVEDJOBS';

export const GET_LIST = `${MODULE}/GET_LIST`
export const GOT_LIST = `${MODULE}/GOT_LIST`
export const CLEAR_LIST = `${MODULE}/CLEAR_LIST`

export const GET_DETAILS = `${MODULE}/GET_DETAILS`
export const GOT_DETAILS = `${MODULE}/GOT_DETAILS`

export const CREATE = `${MODULE}/CREATE`
export const UPDATE = `${MODULE}/UPDATE`
export const REMOVE = `${MODULE}/REMOVE`
export const APPLY = `${MODULE}/APPLY`
export const UNAPPLY = `${MODULE}/UNAPPLY`
export const SAVE = `${MODULE}/SAVE`
export const UNSAVE = `${MODULE}/UNSAVE`

export const SET_FORM_DATA = `${MODULE}/SET_FORM_DATA-A`
export const CLEAR_FORM_DATA = `${MODULE}/CLEAR_FORM_DATA-A`
