import React, { PureComponent, Fragment } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import seals from "assets/assets/seals.png";
import sealsMx from "assets/assets/seals-mx.png";

class Footer extends PureComponent {
  render() {
    return (
      <Fragment>
        <div className="prefooter bg-gray-darkest">
          <div className="container">
            <div className="col-12">
              <img src={seals} alt="" className="seals img-fluid" />
              <img src={sealsMx} alt="" className="sealMx img-fluid" />
            </div>
          </div>
        </div>
        <div className="section-relative section-top-66 section-bottom-34 page-footer bg-gray-darkest pt-5 pb-5">
          <div className="container pb-5">
            <div className="row justify-content-md-center text-lg-left">
              <div className="col-md-12 col-lg-12">
                <div className="row justify-content-sm-center">
                  <div className="footer-card col-sm-12 col-lg-3 offset-top-50 offset-lg-top-0 order-lg-3 text-sm-left">
                    <h6 className="text-uppercase text-spacing-60 font-default text-white">
                      SITE MAP
                    </h6>
                    <div className="d-block" id="sitemap">
                      <div className="list list-unstyled list-inline-primary">
                        <ul className="quickLinks">
                          <li className="list-inline-item text-primary m-0 mr-0">
                            <Link to="/aboutus">About</Link>
                            <ul className="footer-bullet p-sm-0">
                              <li>
                                {" "}
                                <Link
                                  className="text-primary"
                                  to="/ipams-chronicles"
                                >
                                  IPAMS&nbsp;Chronicles
                                </Link>
                              </li>
                              <li>
                                {" "}
                                <Link className="text-primary" to="/awards">
                                  Awards &amp; Recognition
                                </Link>
                              </li>
                            </ul>
                          </li>
                          <li className="list-inline-item text-primary m-0 mr-0">
                            News & Events
                            <ul className="footer-bullet p-sm-0">
                              <li>
                                {" "}
                                <Link className="text-primary" to="/advisories">
                                  Advisories
                                </Link>
                              </li>
                              <li>
                                {" "}
                                <Link
                                  className="text-primary"
                                  to="/events/today"
                                >
                                  Events
                                </Link>
                              </li>
                              <li>
                                {" "}
                                <Link className="text-primary" to="/interview">
                                  Interview Schedules
                                </Link>
                              </li>
                            </ul>
                          </li>
                          <li className="list-inline-item text-primary m-0 mr-0">
                            <Link className="text-primary" to="/social-media">
                              Social Media
                            </Link>
                          </li>
                          <li className="list-inline-item text-primary m-0 mr-0">
                            <Link className="text-primary" to="/employers">
                              Employers
                            </Link>
                          </li>
                          <li className="list-inline-item text-primary m-0 mr-0">
                            <Link className="text-primary" to="/jobseeker">
                              Job Seekers
                            </Link>
                          </li>
                          <li className="list-inline-item text-primary m-0 mr-0">
                            <Link className="text-primary" to="/faq">
                              FAQ
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="footer-card col-sm-12 col-lg-3 offset-top-50 offset-lg-top-0 order-lg-3 text-sm-left">
                    <h6 className="text-uppercase text-spacing-60 font-default text-white">
                      QUICK LINKS
                    </h6>
                    <div className="d-block" id="quickLinks">
                      <div className="list list-unstyled list-inline-primary">
                        <ul className="quickLinks">
                          <li className="list-inline-item text-primary m-0 mr-0">
                            <Link to="/ofw-testimonials">OFW Testimonials</Link>
                          </li>
                          <li className="list-inline-item text-white m-0 mr-0 mt-3">
                            Documents Required
                          </li>
                          <ul className="footer-bullet p-sm-0">
                            <li>
                              {" "}
                              <Link className="text-primary" to="/cabin-crew">
                                Cabin Crew
                              </Link>
                            </li>
                            <li>
                              {" "}
                              <Link className="text-primary" to="/healthcare">
                                Healthcare
                              </Link>
                            </li>
                            {
                              // <li> <Link className="text-primary" to="/emirates">Dnata/Emirates</Link></li>
                            }
                            <li>
                              {" "}
                              <Link className="text-primary" to="/canada">
                                Canada
                              </Link>
                            </li>
                          </ul>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div className="footer-card col-sm-12 col-lg-4 offset-top-50 offset-lg-top-0 order-lg-1 text-center">
                    <div className="footer-logo">
                      <Link to="/">
                        <img
                          style={{ maxWidth: 170 }}
                          src="https://ipams-it.s3.ap-southeast-1.amazonaws.com/CLOUD%20FILES/IPAMS%2050yrs%20v5.png?fbclid=IwAR2IMRFa58YTk3-3Xn6Cr3QN6Jy8OSdH-tJsABtBwEoXqr8pp7klezYRkbo"
                          alt=""
                        />
                      </Link>
                    </div>
                    <ul className="list-inline list-inline-sm d-inline-block offset-top-34 post-meta text-dark list-inline-primary mt-0 mb-0">
                      <li className="list-inline-item mx-1 p-0">
                        <a
                          href="https://www.facebook.com/ipamsph"
                          target="_blank"
                        >
                          <i className="fab fa-facebook-square fa-2x"></i>
                        </a>
                      </li>
                      <li className="list-inline-item mx-1 p-0">
                        <a
                          href="https://www.linkedin.com/company/ipams"
                          target="_blank"
                        >
                          <i className="fab fa-linkedin fa-2x"></i>
                        </a>
                      </li>
                      <li className="list-inline-item mx-1 p-0">
                        <a
                          href="https://www.youtube.com/channel/UCyvbU-j7xNjBKiBmgvCMUDQ"
                          target="_blank"
                        >
                          <span className="icon fa-youtube-play fa-2x"></span>
                        </a>
                      </li>
                      <li className="list-inline-item mx-1 p-0">
                        <a
                          href="https://www.instagram.com/ipamsph"
                          target="_blank"
                        >
                          <i className="fab fa-instagram fa-2x"></i>
                        </a>
                      </li>
                      <li
                        className="list-inline-item mx-1 p-0"
                        style={{ height: 50, width: 26 }}
                      >
                        <a
                          href="https://twitter.com/ipamsph"
                          className="tiktok-icon fa-2x mb-1"
                          target="_blank"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            // xmlns:xlink="http://www.w3.org/1999/xlink"
                            version="1.1"
                            // width="25"
                            // height="25"
                            viewBox="0 0 256 256"
                            // xml:space="preserve"
                          >
                            <defs></defs>
                            <g
                              style={{
                                stroke: "none",
                                strokeWidth: "0",
                                strokeDasharray: "none",
                                strokeLinecap: "butt",
                                strokeLinejoin: "miter",
                                strokeMiterlimit: 10,
                                // fill: "none",
                                fillRule: "nonzero",
                                opacity: 1,
                              }}
                              transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)"
                            >
                              <polygon
                                points="24.89,23.01 57.79,66.99 65.24,66.99 32.34,23.01 "
                                // style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(0,0,0); fill-rule: nonzero; opacity: 1;"
                                transform="  matrix(1 0 0 1 0 0) "
                              />
                              <path
                                d="M 45 0 L 45 0 C 20.147 0 0 20.147 0 45 v 0 c 0 24.853 20.147 45 45 45 h 0 c 24.853 0 45 -20.147 45 -45 v 0 C 90 20.147 69.853 0 45 0 z M 56.032 70.504 L 41.054 50.477 L 22.516 70.504 h -4.765 L 38.925 47.63 L 17.884 19.496 h 16.217 L 47.895 37.94 l 17.072 -18.444 h 4.765 L 50.024 40.788 l 22.225 29.716 H 56.032 z"
                                style={{
                                  stroke: "none",
                                  strokeWidth: "1",
                                  strokeDasharray: "none",
                                  strokeLinecap: "butt",
                                  strokeLinejoin: "miter",
                                  strokeMiterlimit: "10",
                                  // fill: "rgb(0,0,0)",
                                  // fillRule: "nonzero",
                                  opacity: "1",
                                }}
                                transform=" matrix(1 0 0 1 0 0) "
                                strokeLinecap="round"
                              />
                            </g>
                          </svg>
                        </a>
                      </li>
                      <li
                        className="list-inline-item mx-1 p-0 "
                        style={{ height: 50, width: 20 }}
                      >
                        <a
                          href="https://www.tiktok.com/@ipamsph"
                          target="_blank"
                          className="tiktok-icon fa-2x mb-1"
                          // style={{ fill: "#7a7a7a" }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 448 512"
                            style={{ marginBottom: 4 }}
                          >
                            <path d="M448 209.9a210.1 210.1 0 0 1 -122.8-39.3V349.4A162.6 162.6 0 1 1 185 188.3V278.2a74.6 74.6 0 1 0 52.2 71.2V0l88 0a121.2 121.2 0 0 0 1.9 22.2h0A122.2 122.2 0 0 0 381 102.4a121.4 121.4 0 0 0 67 20.1z" />
                          </svg>
                        </a>
                      </li>
                    </ul>
                    <p className="text-dark">
                      IPAMS&copy;<span id="copyright-year"></span>{" "}
                      <Link to="/privacy">. Privacy Policy</Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state, routeParams) => {
  const { isShinked } = state.nav.toJS();
  const { auth } = state;
  return {
    isShinked,
    user_type: auth.get("user_type"),
  };
};

export default withRouter(connect(mapStateToProps)(Footer));
