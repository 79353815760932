import { takeEvery, put, call, all } from 'redux-saga/effects';
import { getFirstMessage } from '../../Helpers';
import {
    loading,
    alert,
    history,
    services,
    watchApiResponse
} from '../../Utils';
import * as c from './constant';

function* getList({args}) {
    const response = yield call(services.get(`api/event`), args);
    yield call(watchApiResponse, response, function*(){
        const { data } = response.data;
        yield put({
            type: c.GOT_LIST,
            data:data
        })
    })
}

function* getToday({args}) {
    const response = yield call(services.get(`api/event`), args);
    yield call(watchApiResponse, response, function*(){
        const { data } = response.data;
        yield put({
            type: c.GOT_TODAY_LIST,
            data:data
        })
    })
}

function* getTodayPublic({args}) {
    const response = yield call(services.get(`service/event`), args);
    yield call(watchApiResponse, response, function*(){
        const { data } = response.data;
        yield put({
            type: c.GOT_TODAY_LIST,
            data:data
        })
    })
}

function* getUpcoming({args}) {
    const response = yield call(services.get(`api/event`), args);
    yield call(watchApiResponse, response, function*(){
        const { data } = response.data;
        yield put({
            type: c.GOT_UPCOMING_LIST,
            data:data
        })
    })
}

function* getUpcomingPublic({args}) {
    const response = yield call(services.get(`service/event`), args);
    yield call(watchApiResponse, response, function*(){
        const { data } = response.data;
        yield put({
            type: c.GOT_UPCOMING_LIST,
            data:data
        })
    })
}


export default function* authSaga() {
    yield all([ 
        takeEvery(c.GET_LIST, getList),
        takeEvery(c.GET_TODAY_LIST, getToday),
        takeEvery(c.GET_TODAY_PUBLIC_LIST, getTodayPublic),
        takeEvery(c.GET_UPCOMING_LIST, getUpcoming),
        takeEvery(c.GET_UPCOMING_PUBLIC_LIST, getUpcomingPublic),
    ])
}