import * as c from './constant';
import { Map, fromJS, List } from 'immutable';

const initState = Map({
	list:Map({
		count:0,
		jobs:Map({
			data:List([])
		})
	}),
	form_data:Map({
		from:'',
		to:''
	})
})

export default (state = initState, action) => {
	switch(action.type){
		case c.GOT_LIST:
			return state.set('list', fromJS(action.data))
		case c.CLEAR_LIST:
			return state.set('list', initState.get('list'))
		case c.GOT_DETAILS:
			return state.set('details', fromJS(action.data))
		case c.SET_FORM_DATA:
			return state.update('form_data',form_data => form_data.merge(fromJS(action.data)));
		default:
			return state;
	}
}