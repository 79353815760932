import { all } from "redux-saga/effects";
import auth from "./modules/auth/saga";
import common from "./modules/common/saga";
import applicant from "app/modules/applicant/saga";
import employment from "app/modules/employment/saga";
import education from "app/modules/education/saga";
import training from "app/modules/training/saga";
import events from "app/modules/events/saga";
import recommended from "app/modules/recommended/saga";
import jobsapplied from "app/modules/jobs-applied/saga";
import savedjobs from "app/modules/saved-jobs/saga";
import news from "app/modules/news/saga";
import balikbayan from "app/modules/balikbayan/saga";
import govdocs from "app/modules/govdocs/saga";
import characterReference from "app/modules/character-reference/saga";

export default function* rootSaga() {
  yield all([
    auth(),
    common(),
    applicant(),
    employment(),
    education(),
    training(),
    events(),
    recommended(),
    jobsapplied(),
    savedjobs(),
    news(),
    balikbayan(),
    govdocs(),
    characterReference(),
  ]);
}
