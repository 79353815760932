import React from 'react';
import { Parallax, Background } from 'react-parallax';
import appStoreLogo from 'assets/assets/ipams-img/app-store.png';
import googlePlay from 'assets/assets/ipams-img/google-play.png';


const App = () => {
    return (
        <Parallax>
                <div className="app ">
                    <div className="section-title container popElement">
                        <h4>New & Improved</h4>
                        <h2 className="">IPAMS MOBILE APP</h2>
                        <div className="row">
                            <span className="line"></span>
                            <h5 className="font-italic">Download now, it's free!</h5>
                        </div>
                    </div>
                    <div className="img-cta container popElement">
                        <a href="https://play.google.com/store/apps/details?id=com.ipams.quantumx&hl=en" target="_blank">
                            <img src={googlePlay} alt="" className="googleplay" />
                        </a>
                        <a href="https://apps.apple.com/ph/app/ipams-mobile/id955398283" target="_blank">
                            <img src={appStoreLogo} alt="" className="appstore" />
                        </a>
                    </div>
                    <div className="backdrop"></div>
                </div>
            </Parallax>
    )
}

export default App;