import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Routes from "./Routes";
import Alert from "react-s-alert";
import AlertTemplate from "./modules/common/component/AlertTemplate";
import AlertMarker from "./modules/common/component/Override";
import Footer from "./modules/auth/component/Footer";
import Header from "./modules/auth/component/Header";

import a from "assets/inside-page-assets/awards-page-title-bg.png";
import b from "assets/inside-page-assets/about-banner.jpg";
import c from "assets/inside-page-assets/job-seerkers-bg.png";
import d from "assets/inside-page-assets/interview-schedules-bg.jpg";
import e from "assets/inside-page-assets/newsevents-bg.jpg";
import f from "assets/inside-page-assets/social-media-bg.jpg";

class App extends Component {
  state = {
    component: null,
  };

  componentWillMount() {
    const { dispatch, isAuthenticated, user_type } = this.props;
    dispatch({
      type: "CHECK_AUTH",
    });
    if (isAuthenticated) {
      dispatch({
        type: "MY_PROFILE",
        user_type: user_type,
      });
    }
  }

  onCloseModal = () => {
    const { dispatch } = this.props;
    dispatch({
      type: "MODAL",
      data: {
        isOpen: false,
      },
    });
  };

  componentWillReceiveProps(nextProps) {
    const { modal } = nextProps;
    if (modal !== this.props.modal) {
      if (modal.isOpen) this.handleOpenModal();

      if (!modal.isOpen) this.setState({ component: null });
    }
  }

  async handleOpenModal() {
    const component = await import("./modules/common/component/Modal");
    this.setState({ component });
  }

  render() {
    const { isAuthenticated, isLoading, modal } = this.props;
    const Modal = this.state.component;
    return (
      <div>
        {/* { isLoading && <Loader /> } */}
        <div className={`${isLoading ? "active" : ""} main-loader`} />
        <Header />
        <div className="page-content">
          <Routes isAuthenticated={isAuthenticated} />
        </div>
        <Footer />
        {Modal && (
          <Modal.default data={modal} onClose={this.onCloseModal}>
            {modal.content}
          </Modal.default>
        )}
        <Alert
          stack={{ limit: 1 }}
          effect="slide"
          contentTemplate={AlertTemplate}
        />
        <div id="my-confirm-modal" />
        <div id="modal-root" />
        <AlertMarker />
      </div>
    );
  }
}

const mapStateToProps = (state, routeParams) => {
  const { isAuthenticated, user_type } = state.auth.toJS();
  const { loadingTypes } = state.loading;
  const modal = state.modal.toJS();
  return {
    isLoading: loadingTypes.length > 0,
    isAuthenticated,
    user_type,
    modal,
  };
};

export default withRouter(connect(mapStateToProps)(App));
