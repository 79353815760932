import { combineReducers } from "redux";
import auth from "app/modules/auth/reducer";
import common from "app/modules/common/reducer";
import applicant from "app/modules/applicant/reducer";
import employment from "app/modules/employment/reducer";
import education from "app/modules/education/reducer";
import training from "app/modules/training/reducer";
import events from "app/modules/events/reducer";
import recommended from "app/modules/recommended/reducer";
import jobsapplied from "app/modules/jobs-applied/reducer";
import savedjobs from "app/modules/saved-jobs/reducer";
import news from "app/modules/news/reducer";
import balikbayan from "app/modules/balikbayan/reducer";
import govdocs from "app/modules/govdocs/reducer";
import characterReference from "app/modules/character-reference/reducer";

const app = combineReducers({
  loading: common.loading,
  modal: common.modal,
  nav: common.nav,
  auth,
  applicant,
  employment,
  education,
  training,
  events,
  recommended,
  jobsapplied,
  savedjobs,
  news,
  balikbayan,
  govdocs,
  characterReference,
});

export default app;
