export const MODULE = "APPLICANT";

export const UPDATE = `${MODULE}/UPDATE`;
export const CHANGE_PASSWORD = `${MODULE}/CHANGE_PASSWORD`;
export const GET_COUNTRY = `${MODULE}/GET_COUNTRY`;
export const GOT_COUNTRY = `${MODULE}/GOT_COUNTRY`;

export const GET_CATEGORY = `${MODULE}/GET_CATEGORY`;
export const GOT_CATEGORY = `${MODULE}/GOT_CATEGORY`;

export const GET_CLIENT = `${MODULE}/GET_CLIENT`;
export const GOT_CLIENT = `${MODULE}/GOT_CLIENT`;

export const GET_LOCATION = `${MODULE}/GET_LOCATION`;
export const GOT_LOCATION = `${MODULE}/GOT_LOCATION`;

export const GET_JOBS = `${MODULE}/GET_JOBS`;
export const GOT_JOBS = `${MODULE}/GOT_JOBS`;

export const CREATE = `${MODULE}/CREATE_GOV_DOCS`;
export const REMOVE = `${MODULE}/REMOVE`;

export const GET_GOV_DOCS = `${MODULE}/GET_GOV_DOCS`;
export const GOT_GOV_DOCS = `${MODULE}/GOT_GOV_DOCS`;

export const SET_FORM_DATA = `${MODULE}/SET_FORM_DATA-A`;
export const CLEAR_FORM_DATA = `${MODULE}/CLEAR_FORM_DATA-A`;
