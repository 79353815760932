import React, { Fragment, useState } from "react";
import Swiper from "react-id-swiper";

const Videos = ({ clients }) => {
  const { innerWidth: width } = window;

  const slidescount = () => {
    if (width < 700) return 3;
    if ((width > 700) & (width < 1200)) return 5;
    if (width > 1200) return 7;
  };
  const params = {
    slideClass: "bg-white",
    // wrapperClass: "bg-white",
    // containerClass: "bg-white",
    // containerClass: "bg-primary",
    // wrapperClass: "bg-danger",
    slidesPerView: slidescount(),
    spaceBetween: 2,
    navigation: {
      nextEl: ".swiper-button-next.slide-arrow.right-c-arrow",
      prevEl: ".swiper-button-prev.slide-arrow.left-c-arrow",
    },
    loop: true,
    speed: 1000,
    autoplay: {
      delay: 100,
      disableOnInteraction: true,
    },
  };

  return (
    <Fragment>
      <div className="clients section bg-white pt-0">
        <div className="section-title pb-5 mb-5">
          <h2 className="text-center">Some of our Clients</h2>
        </div>
        <div className="section-content">
          <Swiper {...params}>
            {clients.map((logo, index) => {
              return (
                <div
                  key={index}
                  className="swiper-slide overflow-hidden"
                  data-swiper-autoplay="1000"
                >
                  <div className="img-wrap">
                    <img
                      src={logo.image_link}
                      alt=""
                      style={{ width: "150px" }}
                    />
                  </div>
                </div>
              );
            })}
          </Swiper>
        </div>
      </div>
    </Fragment>
  );
};

export default Videos;
