import { takeEvery, put, call, all } from "redux-saga/effects";
import { getFirstMessage } from "../../Helpers";
import {
  loading,
  alert,
  history,
  services,
  watchApiResponse,
} from "../../Utils";
import * as c from "./constant";

function* country() {
  const response = yield call(services.get(`api/service/country`));
  yield call(watchApiResponse, response, function* () {
    const { data } = response;
    yield put({
      type: c.GOT_COUNTRY,
      data,
    });
  });
}

function* jobCategory() {
  const response = yield call(services.get(`service/jobcategory`));
  yield call(watchApiResponse, response, function* () {
    const { data } = response.data;
    yield put({
      type: c.GOT_CATEGORY,
      data,
    });
  });
}

function* getClient() {
  const response = yield call(services.get(`api/service/client`));
  yield call(watchApiResponse, response, function* () {
    const { data } = response;
    yield put({
      type: c.GOT_CLIENT,
      data,
    });
  });
}

function* update({ args }) {
  const response = yield call(services.put(`api/service/my`), args);
  yield call(watchApiResponse, response, function* () {
    const { message } = response.data;
    alert.success(message);
    yield put({
      type: "MY_PROFILE",
    });
    yield put({
      type: "MODAL",
      data: {
        isOpen: false,
      },
    });
  });
}

function* location() {
  const response = yield call(services.get(`service/location`));
  yield call(watchApiResponse, response, function* () {
    const { data } = response.data;

    yield put({
      type: c.GOT_LOCATION,
      data,
    });
  });
}

function* changePassword({ args }) {
  const response = yield call(
    services.put(`api/user/web_change_password`),
    args
  );
  yield call(watchApiResponse, response, function* () {
    const { message } = response.data;
    alert.success(message);
  });
  history.push("/applicant/profile");
}

export default function* authSaga() {
  yield all([
    takeEvery(c.UPDATE, update),
    takeEvery(c.GET_COUNTRY, country),
    takeEvery(c.GET_CATEGORY, jobCategory),
    takeEvery(c.GET_CLIENT, getClient),
    takeEvery(c.GET_LOCATION, location),
    takeEvery(c.CHANGE_PASSWORD, changePassword),
  ]);
}
