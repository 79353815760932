import * as c from "./constant";
import { Map, fromJS, List } from "immutable";

const initState = Map({
  isAuthenticated: false,
  profile: Map({}),
  countries: List([]),
  categories: List([]),
  clients: List([]),
  location: List([]),
  members: List([]),
  jobs: List([]),
  form_data: Map({
    lengthUnit: "FEET",
    weightUnit: "KG",
    photo: "",
    jobcat1: "",
    jobcat2: "",
    jobcat3: "",
    firstname: "",
    middlename: "",
    lastname: "",
    address: "",
    location: "",
    email: "",
    password: "",
    password_confirmation: "",
    month: "",
    day: "",
    year: "",
    gender: "",
    civilstatus: "",
    height: "",
    lengthUnit: "",
    weight: "",
    weightUnit: "",
    nationality: "",
    religion: "",
    mobile: "",
    mobile2: "",
    yearsofexp: "",
    resume: "",
    source: "",
    availability: "",
    defect: "",
    date_start: "",
    old_password: "",
    new_password: "",
    confirm_new_password: "",
    skype_id: "",
  }),
});

export default (state = initState, action) => {
  switch (action.type) {
    case c.GOT_COUNTRY:
      return state.set("countries", fromJS(action.data));
    case c.GOT_CATEGORY:
      return state.set("categories", fromJS(action.data));
    case c.GOT_LOCATION:
      return state.set("location", fromJS(action.data));
    case c.SET_FORM_DATA:
      return state.update("form_data", (form_data) =>
        form_data.merge(fromJS(action.data))
      );
    default:
      return state;
  }
};
