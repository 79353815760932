import { takeEvery, put, call, all } from "redux-saga/effects";
import {
  loading,
  alert,
  history,
  services,
  watchApiResponse,
} from "../../Utils";
import * as c from "./constant";

function* getList({ args }) {
  yield put(loading("CHARACTER_REFERENCE_ALL"));

  const response = yield call(
    services.get(`service/character_reference`),
    args
  );
  yield call(watchApiResponse, response, function* () {
    const { data } = response.data;
    yield put({
      type: c.GOT_LIST,
      data: data,
    });

    yield put(loading(null));
  });
}

function* create({ args }) {
  yield put(loading("CREATE"));

  const response = yield call(
    services.post(`service/character_reference`),
    args
  );
  yield call(watchApiResponse, response, function* () {
    const { message } = response.data;
    alert.success(message);
    yield getList({ args });
    yield put({
      type: "MODAL",
      data: {
        isOpen: false,
      },
    });
    yield put(loading(""));
  });
}

function* update({ id, args }) {
  yield put(loading("UPDATE"));

  const response = yield call(
    services.put(`service/character_reference/${id}`),
    args
  );
  yield call(watchApiResponse, response, function* () {
    const { message } = response.data;
    alert.success(message);
    yield getList({ args });
    yield put({
      type: "MODAL",
      data: {
        isOpen: false,
      },
    });
    yield put(loading(""));
  });
}

function* remove({ id, args }) {
  yield put(loading("REMOVE"));

  const response = yield call(
    services.remove(`service/character_reference/${id}`),
    args
  );
  yield call(watchApiResponse, response, function* () {
    const { message } = response.data;
    alert.success(message);
    yield getList({ args });
    yield put({
      type: "MODAL",
      data: {
        isOpen: false,
      },
    });
    yield put(loading(""));
  });
}

export default function* authSaga() {
  yield all([
    takeEvery(c.GET_LIST, getList),
    takeEvery(c.CREATE, create),
    takeEvery(c.UPDATE, update),
    takeEvery(c.REMOVE, remove),
  ]);
}
