import React, { Component } from "react";
import { BrowserRouter as Router, Redirect, Switch, Route } from "react-router-dom";
import { AsyncComponent } from './Utils';
import Home from './modules/auth/container/Home';

const AsyncHomeContainer = AsyncComponent(() => import('./modules/home/container/Home'));
const AsyncSignInContainer = AsyncComponent(() => import('./modules/auth/container/SignInContainer'));
const AboutUs = AsyncComponent(() => import('./modules/auth/container/AboutUs'));
const ExecutiveProfile = AsyncComponent(() => import('./modules/auth/container/ExecutiveProfile'));
const IpamsChronicles = AsyncComponent(() => import('./modules/auth/container/IpamsChronicles'));
const Applicant = AsyncComponent(() => import('./modules/applicant/container/Applicant'));
const Interview = AsyncComponent(() => import('./modules/auth/container/Interview'));
const SocialMedia = AsyncComponent(() => import('./modules/auth/container/SocialMedia'));
const News = AsyncComponent(() => import('./modules/news/container'));
const Events = AsyncComponent(() => import('./modules/events/container/Events'));
const Advisories = AsyncComponent(() => import('./modules/auth/container/Advisories'));
const JobSeeker = AsyncComponent(() => import('./modules/auth/container/Jobseekers'));
const JobSeekersLoginStatic = AsyncComponent(() => import('./modules/auth/container/JobSeekersLoginStatic'));
const Register = AsyncComponent(() => import('./modules/auth/container/Register'));
const Forgot = AsyncComponent(() => import('./modules/auth/container/ForgotPasswordModal'));
const Employers = AsyncComponent(() => import('./modules/auth/container/Employers2'));
const Balikbayan = AsyncComponent(() => import('./modules/auth/container/Balikbayan'));
const ContactUs = AsyncComponent(() => import('./modules/auth/container/ContactUs'));
const Services = AsyncComponent(() => import('./modules/auth/container/Services'));
const Awards = AsyncComponent(() => import('./modules/auth/container/Awards'));
const Faq = AsyncComponent(() => import('./modules/auth/container/Faq'));
const Privacy = AsyncComponent(() => import('./modules/auth/container/Privacy'));
const HealthCare = AsyncComponent(() => import('./modules/auth/container/HealthCare'));
const CabinCrew = AsyncComponent(() => import('./modules/auth/container/CabinCrew'));
const Aramco = AsyncComponent(() => import('./modules/auth/container/Aramco'));
const Emirates = AsyncComponent(() => import('./modules/auth/container/Emirates'));
const ChangePasswordPub = AsyncComponent(() => import('./modules/auth/container/ChangePasswordPub'));
const PageNotFound = AsyncComponent(() => import('./modules/auth/container/PageNotFound'));

const OfwTestimonials = AsyncComponent(() => import('./modules/auth/container/Testimonial'));
const JobInfoRoutedModal = AsyncComponent(() => import('./modules/auth/container/JobInfoRoutedModal'));
const Canada = AsyncComponent(() => import('./modules/auth/container/CanadaProcedure'));
const RemoveAccount = AsyncComponent(() => import('./modules/auth/container/RemoveAccount'));
const RemoveAccountConfirm = AsyncComponent(() => import('./modules/auth/container/RemoveAccountConfirm'));


const PrivateRoute = ({ component: Component, ...rest }) => {
    if (!rest.isAuthenticated)
        return <Redirect to="/" />
    return (
        <Route path="/applicant" component={Applicant} />
    )
}

class MainRoutes extends Component {

    render() {
        const { isAuthenticated } = this.props
        return (
            <Switch>
                <Route exact path="/" component={Home} />
                <Route path="/job/:id" component={JobInfoRoutedModal} />
                <Route path="/aboutus" component={AboutUs} />
                <Route path="/ipams-chronicles" component={IpamsChronicles} />
                <Route path="/executive-profile" component={ExecutiveProfile} />
                <Route path="/interview" component={Interview} />
                <Route path="/social-media" component={SocialMedia} />
                <Route path="/news" component={News} />
                <Route path="/events" component={Events} />
                <Route path="/advisories" component={Advisories} />
                <Route path="/jobseeker" component={JobSeeker} />
                <Route path="/jobseekerslogin" component={JobSeekersLoginStatic} />
                <Route path="/register" component={Register} />
                <Route path="/forgot-password" component={Forgot} />
                <Route path="/employers" component={Employers} />
                <Route path="/balikbayan" component={Balikbayan} />
                <Route path="/services" component={Services} />
                <Route path="/contact" component={ContactUs} />
                <Route path="/awards" component={Awards} />
                <Route path="/signin" component={AsyncSignInContainer} />
                <Route path="/faq" component={Faq} />
                <Route path="/privacy" component={Privacy} />
                <Route path="/ofw-testimonials" component={OfwTestimonials} />

                <Route path="/healthcare" component={HealthCare} />
                <Route path="/cabin-crew" component={CabinCrew} />
                <Route path="/aramco" component={Aramco} />
                <Route path="/emirates" component={Emirates} />
                <Route path="/canada" component={Canada} />
                <Route path="/change-password/:code?" component={ChangePasswordPub} />
                <Route path="/remove-account" component={RemoveAccount} />
                <Route path="/remove-account-confirm/:code?" component={RemoveAccountConfirm} />

                <PrivateRoute key="home" exact={!isAuthenticated} isAuthenticated={isAuthenticated} path="/" component={AsyncHomeContainer} />
                <Route path="*" component={PageNotFound} />

            </Switch>
        )
    }
}


const mapStateToProps = (state, routeParams) => {
    const { loadingTypes } = state.loading;
    const form_data = state.auth.get("form_data");
    const countries = state.auth.get("countries");
    const categories = state.auth.get("categories");
    const clients = state.auth.get("clients");
    const jobs = state.auth.get("jobs");

    return {
        isLoading: loadingTypes.length > 0,
        form_data,
        countries,
        categories,
        clients,
        jobs
    };
};

export default MainRoutes;