import { takeEvery, put, call, all } from 'redux-saga/effects';
import { getFirstMessage } from '../../Helpers';
import {
    loading,
    alert,
    history,
    services,
    watchApiResponse
} from '../../Utils';
import * as c from './constant';

function* getList({args}) {
    const response = yield call(services.get(`api/jobs/saved/list`), args);
    yield call(watchApiResponse, response, function*(){
        const { data } = response.data;

        if(typeof data === 'object' && data.length === undefined){
            return yield put({
                type: c.GOT_LIST,
                data:data
            })
        }
        yield put({
            type: c.CLEAR_LIST
        })
        
        
    })
}


function* apply({id}) {
    const response = yield call(services.put(`api/jobs/${id}/apply`));
    yield call(watchApiResponse, response, function*(){
        const { message } = response.data;
        alert.success(message)
        yield getList({args:{}})
    })
}
function* unApply({id}) {
    const response = yield call(services.get(`api/jobs/${id}/unapply`));
    yield call(watchApiResponse, response, function*(){
        const { message } = response.data;
        alert.success(message)
        yield getList({args:{}})
    })
}

function* save({id}) {
    const response = yield call(services.put(`api/jobs/${id}/save`));
    yield call(watchApiResponse, response, function*(){
        const { message } = response.data;
        alert.success(message)
        yield getList({args:{}})
    })
}
function* unSave({id}) {
    const response = yield call(services.get(`api/jobs/${id}/unsave`));
    yield call(watchApiResponse, response, function*(){
        const { message } = response.data;
        alert.success(message)
        yield getList({args:{}})
    })
}

export default function* authSaga() {
    yield all([ 
        takeEvery(c.GET_LIST, getList),
        takeEvery(c.APPLY, apply),
        takeEvery(c.UNAPPLY, unApply),
        takeEvery(c.SAVE, save),
        takeEvery(c.UNSAVE, unSave),
    ])
}