import { takeEvery, put, call, all } from 'redux-saga/effects';
import { getFirstMessage } from '../../Helpers';
import {
    loading,
    alert,
    history,
    services,
    watchApiResponse
} from '../../Utils';
import * as c from './constant';

function* getList({args}) {
    const response = yield call(services.get(`api/balikbayan`), args);
    yield call(watchApiResponse, response, function*(){
        const { data } = response.data;
        if(typeof data === 'object'){
            yield put({
                type: c.GOT_LIST,
                data:data
            })
        }
    })
}


export default function* authSaga() {
    yield all([ 
        takeEvery(c.GET_LIST, getList),
    ])
}