import React, { Component, Fragment, useEffect } from "react";
import { withRouter, Route, Link } from "react-router-dom";
import { connect } from "react-redux";
import App from "./App";
import Videos from "./Videos";
import Swiper from "react-id-swiper/lib";

import { Parallax } from "react-parallax";

import JobInfoRoutedModal from "./JobInfoRoutedModal";
import { history } from "app/Utils";
import * as c from "../constant";
import coffeeTable from "assets/images/background-client-table.jpg";
import coffeeTableSmall from "assets/images/background-client-table-small.jpg";
import WhyPeopleChooseUs from "./WhyPeopleChooseUs";
import GlobalReach from "./GlobalReach";
import SwiperClients from "./SwiperClients";
import Testimonial2 from "./Testimonial2";
import HomeMainSlider from "./HomeMainSlider";

class Home extends Component {
  constructor(props) {
    super(props);
    this.goNext = this.goNext.bind(this);
    this.goPrev = this.goPrev.bind(this);
    this.swiper = null;
  }

  goNext() {
    if (this.swiper) this.swiper.slideNext();
  }

  goPrev() {
    if (this.swiper) this.swiper.slidePrev();
  }

  componentWillMount() {
    const { dispatch } = this.props;
    dispatch({
      type: c.GET_CLIENT_LOGOS,
    });
  }

  handleChange = (e) => {
    e.preventDefault();
    const { dispatch } = this.props;
    const { name, value } = e.target;
    dispatch({
      type: c.SET_FORM_DATA,
      data: {
        [name]: value || "",
      },
    });
  };

  jobseeker = (e) => {
    e.preventDefault();
    history.push("/jobseeker");
  };

  render() {
    const { match, client_logos } = this.props;

    const params2 = {
      loop: true,
      navigation: {
        nextEl: ".swiper-button-next.slide-arrow.right-c-arrow",
        prevEl: ".swiper-button-prev.slide-arrow.left-c-arrow",
      },
      pagination: {
        el: ".swiper-pagination",
        type: "bullets",
        clickable: true,
      },
      autoplay: {
        delay: 5000,
      },

      speed: 1000,
      spaceBetween: 0,
      containerClass: "swiper-wrapper",
    };

    return (
      <Fragment>
        <HomeMainSlider>
          <div className="backdrop"></div>
          <div className="hero-swiper">
            <Swiper {...params2}>
              <div
                className="swiper-slide hero-slide2"
                data-swiper-autoplay="5000"
              >
                <div className="hero-text text-extra-big text-white font-weight-bold">
                  <h1>
                    Uplifting <span className="em">Lives</span>
                  </h1>
                  <h5 className="pt-3">
                    We provide the best jobs abroad for the Filipino worker,
                    paving the road to their success and thus, uplifting their
                    lives.
                  </h5>
                </div>
              </div>
              <div
                className="swiper-slide hero-slide2"
                data-swiper-autoplay="5000"
              >
                <div className="hero-text text-extra-big text-white font-weight-bold">
                  <h1>
                    {" "}
                    <span className="em">100% </span> No-Fee{" "}
                    <br className="break-control" /> Charging Company
                  </h1>

                  <h5 className="pt-3">
                    We pride ourselves in operating strictly, unquestionably and
                    absolutely as a no-fee charging entity. We do not charge
                    placement fees and processing fees from all our Filipinos we
                    place worldwide, and this has been our long-standing
                    practice for five decades of business.{" "}
                  </h5>
                </div>
              </div>

              <div
                className="swiper-slide hero-slide2"
                data-swiper-autoplay="5000"
              >
                <div className="hero-text text-extra-big text-white font-weight-bold">
                  <h1>
                    {" "}
                    The Highest Standard of{" "}
                    <span className="em">Professionalism</span>
                  </h1>
                  <h5 className="pt-3">
                    The welfare of the Filipino is top of mind at all times. In
                    getting the right people for the right job, we ensure that
                    the highest level of business ethics is status quo. We do
                    not just send people for overseas work, we go the extra mile
                    of providing assistance on all employee concerns.{" "}
                  </h5>
                </div>
              </div>
              <div
                className="swiper-slide hero-slide2"
                data-swiper-autoplay="5000"
              >
                <div className="hero-text text-extra-big text-white font-weight-bold">
                  <h1>
                    {" "}
                    Your Workforce <br className="break-control" />{" "}
                    <span className="em"> Solutions Partner </span>
                  </h1>
                  <h5 className="pt-3">
                    We are your fast, efficient and reliable partner in
                    supplying qualified people spanning across all industries.
                    From engineering, technical work, hospitality, aviation,
                    management, healthcare, to operations and other industries,
                    we answer to your workforce needs.
                  </h5>
                </div>
              </div>
            </Swiper>
          </div>
        </HomeMainSlider>

        <GlobalReach />
        <div className="overflow-hidden w-100">
          {client_logos.size > 0 && (
            <SwiperClients clients={client_logos.toJS()} />
          )}
        </div>
        <Parallax
          blur={0}
          bgImage={window.innerWidth <= 400 ? coffeeTableSmall : coffeeTable}
          bgImageAlt="the cat"
          strength={window.innerWidth <= 400 ? 600 : 1200}
          style={{ backgroundSize: `cover` }}
          bgImageStyle={{ height: "auto", width: "auto", top: "200px" }}
        >
          <section
            className="section-98 section-md-110 popElement"
            // style={{background:`url(${coffeeTable})`, backgroundRepeat: 'no-repeat', backgroundSize: '100%', botton:'0'}}
          >
            <div className="container text-center">
              <h3 className="text-center mt-3 text-white">
                IPAMS is a top DMW-licensed and multi-awarded Philippine
                Recruitment Agency specializing in the career placement of
                Filipino professionals and workers.{" "}
              </h3>

              <div className="row">
                <div className="col-12 mt-2 mb-5">
                  <p className="text-center text-white">
                    IPAMS is an ABSOLUTE NO-FEE CHARGING COMPANY and is known as
                    the most reputable excelling DMW-licensed recruitment agency
                    in the country.{" "}
                  </p>
                  <p className="text-center text-white">
                    IPAMS does not collect placement fees, processing fees, or
                    salary deductions from ALL Filipinos recruited worldwide.
                    Since 1972, around 62,000 IPAMS candidates have been placed
                    and are well-settled in their careers overseas.
                  </p>
                </div>
              </div>
            </div>
          </section>
          <div className="backdrop"></div>
        </Parallax>
        <section className="section-98 section-md-110 ">
          <div className="container">
            <h1 className="text-center title">Why People Choose Us</h1>
            <WhyPeopleChooseUs />
          </div>
        </section>

        <Videos />

        <section className=" ">
          <Testimonial2 />
        </section>

        <div className="col-12 ">{/* <Jobseekers /> */}</div>

        <Route path={`${match.path}/job/:id`} component={JobInfoRoutedModal} />
        <App />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  const { loadingTypes } = state.loading;
  const form_data = state.auth.get("form_data");
  const jobs = state.auth.get("jobs");
  const client_logos = state.auth.get("client_logos");

  return {
    isLoading: loadingTypes.length > 0,
    jobs,
    form_data,
    client_logos,
  };
};

export default withRouter(connect(mapStateToProps)(Home));
