import React from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import configureStore from "./app/Store";
import App from "./app/App";
import { ConnectedRouter } from "react-router-redux";
import history from "./app/History";
import registerServiceWorker from "./registerServiceWorker";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-select/dist/react-select.css';
import 'react-s-alert/dist/s-alert-default.css';
import 'react-s-alert/dist/s-alert-css-effects/slide.css';
import "./assets/scss/main.css";
import "./assets/css/main_override.css";

import "react-datepicker/dist/react-datepicker.css";
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import { registerLocale } from  "react-datepicker";
import es from 'date-fns/locale/es';
registerLocale('es', es)

const rootElement = document.getElementById("root");

const appRender = async () => {
  const store = await configureStore();
  render(
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <App />
      </ConnectedRouter>
    </Provider>,
    rootElement
  );
};

appRender();
registerServiceWorker();
