export const MODULE = "EMPLOYMENT";

export const CREATE = `${MODULE}/CREATE`;
export const UPDATE = `${MODULE}/UPDATE`;
export const REMOVE = `${MODULE}/REMOVE`;

export const GET_LIST = `${MODULE}/GET_LIST`;
export const GOT_LIST = `${MODULE}/GOT_LIST`;
export const GET_EDUCATION = `${MODULE}/GET_EDUCATION`;
export const GOT_EDUCATION = `${MODULE}/GOT_EDUCATION`;

export const GET_CATEGORY = `${MODULE}/GET_CATEGORY`;
export const GOT_CATEGORY = `${MODULE}/GOT_CATEGORY`;
